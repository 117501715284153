import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DbPath } from '@app/shared/utils/db-path';
import { createInquiryRequest } from '@app/store/inquiries/inquiries.models';
import {Observable} from "rxjs";
import {ApiService} from "@app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class InquiriesService {

  constructor(
    private afs: AngularFirestore,
    private apiService: ApiService
  ) { }

  async createInquiries(payload: createInquiryRequest) : Promise<any> {
    return await this.afs.collection(DbPath.Merchants).doc(payload.merchantId).collection(DbPath.Inquiries).add(payload.inquiryInfo).then(docRef =>
      docRef.id
    );
  }

  createInquiryViaApi(merchantCode: string, inquiry: any, recaptchaToken: string): Observable<any>{
    return this.apiService.postData(`merchants/${merchantCode}/inquiries`, {
      inquiry,
      recaptchaToken
    });
  }
}
